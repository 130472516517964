import axios from './index'

export const GET_PROJECT_ID = 'kiosks/data/'
export const GET_SPEECH_TO_TEXT = 'files/speech_to_text/'
export const SET_CHANGE_LANGUAGE = 'kiosks/change_language/'
export const SET_ANSWER = 'contents/answer/'
export const SET_HIDDEN_CATCH_SELECT = 'contents/hidden_catch_select/'
export const SET_HIDDEN_CATCH_COMPLETE = 'contents/hidden_catch_complete/'

export default {
  axiosGetProjectId (serial) {
    return axios.post(GET_PROJECT_ID, { serial })
  },
  axiosGetSpeechToText (params) {
    return axios.post(GET_SPEECH_TO_TEXT, params, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  axiosSetChangeLanguage (params) {
    return axios.post(SET_CHANGE_LANGUAGE, params)
  },
  axiosSetAnswer (params) {
    return axios.post(SET_ANSWER, params)
  },
  axiosSetHiddenCatchSelect (params) {
    return axios.post(SET_HIDDEN_CATCH_SELECT, params)
  },
  axiosSetHiddenCatchComplete (params) {
    return axios.post(SET_HIDDEN_CATCH_COMPLETE, params)
  }
}
