<template>
  <div class="ai-curator-container">
    <div class="row">
      <div class="ai-curator-bottom-wrap col-xl-12">

        <div class="ai-curator-bottom-button" v-show="!hiddenCatch">
          <p><button id="record" class="btn" :class="{'active' : recording}"><img :src="require('@/assets/images/microphone.png')" class="microphone" /></button></p>
        </div>

        <div class="ai-curator-bottom-ox-button">
          <button class="btn" @click="handleQuiz('O')" :disable="isDisable" style="width:254px; height:254px;">
            <!-- <img :src="require('@/assets/images/O.png')" class="ox-btn"/> -->
            <img src="../components/img/blueO.png" class="ox-btn"/>
          </button>
          <button class="btn" @click="handleQuiz('X')" style="margin-left: 58px; width:254px; height:254px;" :disable="isDisable">
            <!-- <img :src="require('@/assets/images/X.png')" class="ox-btn"/> -->
            <img src="../components/img/redX.png" class="ox-btn"/>
          </button>
        </div>

        <div class="ai-curator-bottom-hidden-catch" style="display:none">
            <div class="catch-div" @click="selectCheckPoint">
              <img :src="getSrc(hiddenCatchData, 0)"/>
              <div class="hidden-circle" v-for="(v,i) in hiddenCatchCircles" :key="'circle_'+i" :style="getHiddenCatchStyle(v)">
                <img class="hidden-circle" :src="require('@/assets/images/select.png')"/>
              </div>
              <!-- <div class="hidden-circle" v-for="(v,i) in item.circles" :key="'circle_'+i" :style="getStyle(v, item)"></div> -->
            </div>
            <div class="catch-div" @click="selectCheckPoint">
              <img :src="getSrc(hiddenCatchData, 1)"/>
              <div class="hidden-circle" v-for="(v,i) in hiddenCatchCircles" :key="'circle_'+i" :style="getHiddenCatchStyle(v)">
                <img class="hidden-circle" :src="require('@/assets/images/select.png')"/>
              </div>
              <!-- <div class="hidden-circle" v-for="(v,i) in item.circles" :key="'circle_'+i" :style="getStyle(v, item)"></div> -->
            </div>
        </div>

        <div class="ai-curator-bottom-language-button">
          <button class="btn bottom-language" :class="{'btn-danger' : lang === 'ko-KR', 'btn-primary' : lang !== 'ko-KR'}" @click="changeLang('ko-KR')"><img src="../components/img/korea.png" style="width: 52px; height: 52px; float: left; margin-left: 40px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;">한국어</p></button>
          <button class="btn bottom-language" :class="{'btn-danger' : lang === 'en-US', 'btn-primary' : lang !== 'en-US'}" @click="changeLang('en-US')"><img src="../components/img/england.png" style="width: 52px; height: 52px; float: left; margin-left: 40px;"><p style="margin-bottom: 0; float: left; padding-left: 14px;">ENGLISH</p></button>
          <button class="btn bottom-language" :class="{'btn-danger' : lang === 'zh-CN', 'btn-primary' : lang !== 'zh-CN'}" @click="changeLang('zh-CN')"><img src="../components/img/china.png" style="width: 52px; height: 52px; float: left; margin-left: 40px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;">中國語</p></button>
          <button class="btn bottom-language" :class="{'btn-danger' : lang === 'ja-JP', 'btn-primary' : lang !== 'ja-JP'}" @click="changeLang('ja-JP')"><img src="../components/img/japan.png" style="width: 52px; height: 52px; float: left; margin-left: 40px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;">日本語</p></button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import url("../../assets/css/ai-qurator-bottom.css");
</style>
<script>
import { core, FIREBASECONFIG } from '../../config/pluginInit'
// import axios from 'axios'
import _axios from '../services/backend'
import $ from 'jquery'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getDatabase, ref, onValue } from 'firebase/database'
export default {
  name: 'AIStudio',
  components: {},
  data () {
    return {
      serial: 'A1',
      projectId: 1,
      state: 'SUCCESS',
      lang: 'ko-KR',
      recording: false,
      quiz: false,
      hiddenCatch: false,
      isDisable: false,
      isFirst: true,
      isHiddenCatchFirst: true,
      hiddenCatchData: [],
      hiddenCatchCount: 0,
      hiddenCatchCircles: []
    }
  },
  mounted () {
    core.index()
    this.init()
    setTimeout(() => {
      this.micropone()
      $('.ai-curator-bottom-language-button').fadeIn('slow')
    }, 500)
    document.oncontextmenu = () => {
      return false
    }
  },
  created () {
    this.getProjectId()
  },
  methods: {
    async handleQuiz (str) {
      const ths = this
      this.isDisable = true
      await _axios.axiosSetAnswer({ serial: this.serial, answer: str })
      setTimeout(() => {
        ths.quiz = false
        $('.ai-curator-bottom-hidden-catch').hide()
        $('.ai-curator-bottom-ox-button').hide()
        $('.ai-curator-bottom-language-button').fadeIn(2000)
        this.isDisable = false
      }, 3000)
    },
    async init () {
      const ths = this

      // Initialize Firebase
      this.app = initializeApp(FIREBASECONFIG)
      this.analytics = getAnalytics(this.app)
      const db = getDatabase(this.app)
      onValue(ref(db, 'quiz/' + this.serial), (snapshot) => {
        console.log(this.serial)
        if (ths.isFirst) {
          ths.isFirst = false
        } else {
          if (snapshot.val()) {
            ths.quiz = true
            $('.ai-curator-bottom-language-button').hide()
            $('.ai-curator-bottom-hidden-catch').hide()
            $('.ai-curator-bottom-ox-button').fadeIn(2000)
          }
        }
      })
      onValue(ref(db, 'hidden_catch/' + this.serial), (snapshot) => {
        if (ths.isHiddenCatchFirst) {
          ths.isHiddenCatchFirst = false
        } else {
          if (snapshot.val()) {
            console.log('asdsad')
            ths.hiddenCatch = true
            const datas = (JSON.parse(snapshot.val()))
            ths.hiddenCatchData = datas.hidden_catch
            ths.hiddenCatchCount = datas.count
            $('.ai-curator-bottom-language-button').hide()
            $('.ai-curator-bottom-ox-button').hide()
            $('.ai-curator-bottom-hidden-catch').fadeIn(2000)
          }
        }
      })
    },
    async getProjectId () {
      const serial = localStorage.getItem('serial')
      if (serial === null) {
        this.$router.replace('/')
      } else {
        this.serial = serial
        const rs = await _axios.axiosGetProjectId({ serial: this.serial })
        if (rs.data.length > 0) {
          this.projectId = rs.data[0].project
        }
      }
    },
    getSrc (item, index) {
      let url = ''
      const seq = index || 0
      if (item.length > 0) {
        url = item[seq].url
        url = url.replace('http://127.0.0.1:8000', '')
      }

      // if (item.type === 'people') {
      //   url = this.characterList[this.modalData.character].src
      // }
      // console.log(url)
      return url
    },
    getHiddenCatchStyle (v) {
      return {
        position: 'absolute',
        left: (v.left - 28) + 'px',
        top: (v.top - 28) + 'px',
        width: 56 + 'px',
        height: 56 + 'px',
        zIndex: 10
      }
    },
    async selectCheckPoint (e) {
      if (e.target.className === 'hidden-circle') {
        return
      }
      console.log(this.hiddenCatchCount)
      if (this.hiddenCatchCount <= this.hiddenCatchCircles.length) {
        return
      }

      const item = {
        left: e.offsetX * 1.754,
        top: e.offsetY * 1.754
      }
      this.hiddenCatchCircles.push(item)
      await _axios.axiosSetHiddenCatchSelect({ serial: this.serial, hiddenCatchSelect: item })
      if (this.hiddenCatchCount <= this.hiddenCatchCircles.length) {
        await _axios.axiosSetHiddenCatchComplete({ serial: this.serial, hiddenCatchComplete: item })
        setTimeout(() => {
          this.hiddenCatch = false
          this.hiddenCatchCircles = []
          $('.ai-curator-bottom-hidden-catch').hide()
          $('.ai-curator-bottom-ox-button').hide()
          $('.ai-curator-bottom-language-button').fadeIn(2000)
        }, 3000)
      }
    },
    changeLang (lang) {
      this.lang = lang
      const rs = _axios.axiosSetChangeLanguage({ serial: this.serial, lang: lang })
      rs.then((response) => {
        console.log(response.data)
      })
    },
    micropone () {
      const ths = this
      // Set up the AudioContext.
      // const audioCtx = new AudioContext()

      // Top-level variable keeps track of whether we are recording or not.
      let recording = false
      console.log(navigator.mediaDevices)
      // Ask user for access to the microphone.
      if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          // Instantiate the media recorder.
          const mediaRecorder = new MediaRecorder(stream)

          // Create a buffer to store the incoming data.
          let chunks = []
          mediaRecorder.ondataavailable = (event) => {
            console.log('event', event)
            chunks.push(event.data)
          }

          // When you stop the recorder, create a empty audio clip.
          mediaRecorder.onstop = (event) => {
            const audio = new Audio()
            audio.setAttribute('controls', '')
            $('#sound-clip').append(audio)
            $('#sound-clip').append('<br />')

            // Combine the audio chunks into a blob, then point the empty audio clip to that blob.
            const blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' })
            audio.src = window.URL.createObjectURL(blob)
            const formData = new FormData()
            formData.append('file', blob, 'audio.webm')
            formData.append('projectId', ths.projectId)
            formData.append('lang', ths.lang)
            formData.append('serial', ths.serial)
            const rs = _axios.axiosGetSpeechToText(formData)
            rs.then((response) => {
              console.log(response.data)
              if (response?.data?.error === '0001') {
                return false
              }
            })

            // Clear the `chunks` buffer so that you can record again.
            chunks = []
          }

          $('#record').on('mousedown', () => {
            mediaRecorder.start()
            recording = true
            ths.recording = true
          })
          $('html').on('mouseup', () => {
            if (recording) {
              mediaRecorder.stop()
              recording = false
              ths.recording = false
            }
          })
          $('#record').on('touchstart', () => {
            mediaRecorder.start()
            recording = true
            ths.recording = true
          })
          $('html').on('touchend', () => {
            if (recording) {
              mediaRecorder.stop()
              recording = false
              ths.recording = false
            }
          })
        }).catch((err) => {
          console.log(err)
          // Throw alert when the browser is unable to access the microphone.
          alert("Oh no! Your browser cannot access your computer's microphone.")
        })
      } else {
        // Throw alert when the browser cannot access any media devices.
        alert("Oh no! Your browser cannot access your computer's microphone. Please update your browser.")
      }
    }
  }
}
</script>
